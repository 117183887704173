














import { Component, Mixins, Vue } from 'vue-property-decorator'
import SidebarSwitcher from '@/components/organisms/v2/SidebarSwitcher.vue'
import EntranceStudentHistoryHeader from '@/components/organisms/EntranceStudentHistoryHeader.vue'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import { EntranceChoiceSchool } from '@/models/api/studentGoal'
import StudentGoalApi from '@/mixins/studentGoals/StudentGoalApi'
import ClassModeMethods from '@/mixins/utils/ClassModeMethods'
import ClassModeChangeAble from '@/mixins/student/ClassModeChangeAble'
import { EntranceInfo } from '@/models/api/history/entrance.ts'
import _ from 'lodash'

@Component({
  components: {
    SidebarSwitcher,
    EntranceStudentHistoryHeader,
    ColoredBox,
  },
})
export default class TargetSituation extends Mixins(StudentGoalApi, ClassModeMethods, ClassModeChangeAble) {
  private userId = Vue.prototype.$cookies.get('authGdls').currentApiUserId

  private entranceData: EntranceInfo = { school: '-', date: '-', countdown: '-' }

  private get classMode() {
    return this.CLASS_MODE.ENTRANCE
  }

  private currentGoal: EntranceChoiceSchool | null = null

  private async mounted(): Promise<void> {
    Vue.prototype.$loading.start()
    await this.checkClassModeAndSideMenuMode()
    this.currentGoal = await this.loadCurrentGoal(this.classMode)
    await this.setEntranceData()
    Vue.prototype.$loading.complete()
  }

  private async setEntranceData(): Promise<void> {
    Vue.prototype.$http.httpWithToken
      .get(`/history/entranceInformation`, {
        params: { userId: this.userId },
      })
      .then((res: any) => {
        if (!_.isEmpty(res.data)) this.entranceData = res.data
      })
  }
}
