




















import { Component, Vue, Prop } from 'vue-property-decorator'
import LabelBase from '@/components/atoms/LabelBase.vue'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import EntranceInfomeation from '@/components/organisms/EntranceInfomeation.vue'
import { EntranceInfo } from '@/models/api/history/entrance.ts'

@Component({
  components: {
    LabelBase,
    ColoredBox,
    EntranceInfomeation,
  },
})
export default class EntranceStudentHistoryHeader extends Vue {
  @Prop({ default: { school: '-', date: '-', countdown: '-' } })
  entranceData!: EntranceInfo

  private learningTypeDatas: object[] = [
    {
      label: '通常',
      value: '1',
      url: '/student/history/normal/target-situation',
    },
    {
      label: '対策',
      value: '2',
      url: '/student/history/test/step-situation',
    },
    {
      label: '講習会',
      value: '3',
      url: '/student/history/seminar/learning-situation',
    },
    {
      label: '入試',
      value: '4',
      url: '/student/history/entrance/pretest-situation',
    },
    { label: '補助', value: '5', url: '/student/history/assist-material' },
  ]

  private learningType = '4'

  get isActive(): any {
    return function (target: string): { [key: string]: boolean } {
      // @ts-ignore
      const { path } = this.$route
      const active = path.split('/').slice(-1)[0]
      return {
        active: target === active,
      }
    }
  }
}
